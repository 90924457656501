.invoice-container {
  background: #ffffff;
  padding: 80px 24px;
}
.inv-details-container {
  width: 380px;
  max-width: 100%;
  margin: 0px auto;
  background: #f8f8fa;
}
.inv-details-text {
  text-align: center;
  font-weight: bold;
  padding: 16px 0;
  font-size: 16px;
}
.details-wrapper {
  padding: 20px 8px;
  background: #ffffff;
  border: 6px solid #f8f8fa;
  border-top: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.due-on-wrapper {
  display: flex;
  align-items: left;
  flex-direction: column;
  gap: 4px;
}
.due-for-wrapper {
  display: flex;
  justify-content: space-between;
}
.due-on-wrapper > .title,
.due-for-wrapper > .title {
  font-weight: bold;
}
.field_wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
  width: 380px;
  max-width: 100%;
  margin: 0px auto;
}
.input-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.input-wrapper > label {
  font-size: 16px;
}
.input-box {
  font-size: 16px;
  padding: 6px;
  border-radius: 8px;
  border: 1px solid #b3b3b3;
  text-align: right;
}
.amount-wrap {
  display: flex;
  flex-direction: column;
}
.amount-wrap > span {
  text-align: right;
  font-size: 10px;
}

.radio-group-container {
  margin-top: 16px;
}
.radio-group-label {
  font-size: 16px;
  font-weight: bold;
}

.radio-container {
  display: flex;
  gap: 24px;
  margin: 16px 0;
}

.radio-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.radio-input {
  appearance: none;
  width: 20px;
  height: 20px;
  margin: 0;
  border: 2px solid #267496;
  border-radius: 50%;
  margin-right: 10px;
  outline: none;
  cursor: pointer;
  position: relative;
}
.radio-input:checked {
  background-color: #267496;
}

.radio-input::before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  margin: auto;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
}

.radio-input:checked::before {
  visibility: visible;
}

.radio-input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.submit-button {
  background-color: #267496;
  color: #fff;
  border: none;
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.submit-btn {
  display: flex;
  justify-content: flex-end;
}

.submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: #FFFFFF;
}

.progress-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  width: 100%;
}

.step {
  display: flex;
  align-items: center;
  position: relative;
}

.step-circle {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold;
  color: white;
}

.step-text {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

.step.done .step-circle {
  background-color: #092B4F;
}

.step.active .step-circle {
  background-color: #01D4A1;
}

.step.pending .step-circle {
  background-color: #d1d5db;
}

.step.done .step-text {
  color: #000000;
}

.step.active .step-text {
  color: #000000;
}

.step.pending .step-text {
  color: #000000;
}

.invoice-step-bar {
  height: 2px !important;
  margin: 0 24px;
  width: 96px;
  background-color: #d1d5db;
  position: relative;
}

@media (max-width: 600px) {
  .progress-wrapper {
    width: 100%;
  }
  
  .invoice-step-bar {
    width: 48px;
    margin: 0 12px;
  }
  
  .step-text {
    font-size: 12px;
  }
}